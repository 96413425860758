"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useTranslationImpl = void 0;
function init(data) {
    return data;
}
/**
 * English teminology
 * https://laskuvarjotoimikunta.ilmailuliitto.fi/wp-content/uploads/sites/7/2024/01/jump_program_sl_21.1.2024.pdf
 * https://laskuvarjotoimikunta.ilmailuliitto.fi/wp-content/uploads/sites/7/2024/01/jump_program_aff_21_1_2024.pdf
 * https://laskuvarjotoimikunta.ilmailuliitto.fi/wp-content/uploads/sites/7/2022/05/skydivers-guide-2022.pdf
 */
const translations = init({
    // space is significant
    'student.unhappyPrefix': {
        'fi': 'Tarvitsee ',
        'en': 'Needs '
    },
    // spaces are significant
    'student.unhappyJoin': {
        'fi': ' ja ',
        'en': ' and '
    },
    'affInstructor.label': {
        'fi': "NOVA-mesuna",
        'en': "AFF instructor",
    },
    'affInstructor.dayLabel': {
        'fi': "NOVA-mesu",
        'en': "AFF instructor",
    },
    'affInstructor1st.ok': {
        'fi': "NOVA-mesu ✓",
        'en': "AFF-I ✓",
    },
    'affInstructor1st.twoNotFound': {
        'fi': "kaksi NOVA-mesua",
        'en': "two AFF instructors",
    },
    'affInstructor2nd.ok': {
        'fi': "NOVA-mesu ✓",
        'en': "2nd AFF-I ✓",
    },
    'affInstructor2nd.notFound': {
        'fi': "toisen NOVA-mesun",
        'en': "2nd AFF instructor",
    },
    'affInstructor1st.oneNotFound': {
        'fi': "NOVA-mesun",
        'en': "an AFF instructor",
    },
    'slInstructor.label': {
        'fi': "PL-mesuna",
        'en': "S-L instructor",
    },
    'slInstructor.dayLabel': {
        'fi': "PL-mesu",
        'en': "S-L instructor",
    },
    'slInstructor.ok': {
        'fi': 'PL-mesu ✓',
        'en': 'S-L instructor ✓',
    },
    'slInstructor.notFound': {
        'fi': 'PL-mesun',
        'en': 'S-L instructor',
    },
    'coach.label': {
        'fi': 'VPK:na',
        'en': 'Coach',
    },
    'coach.dayLabel': {
        'fi': 'VPK',
        'en': 'coach',
    },
    'coach.ok': {
        'fi': 'VPK ✓',
        'en': 'Coach ✓',
    },
    'coach.notFound': {
        'fi': 'VPK:n',
        'en': 'a coach',
    },
    'tandemInstructor.label': {
        'fi': 'Tandem-mesuna',
        'en': 'Tandem instructor',
    },
    'tandemInstructor.dayLabel': {
        'fi': 'tandem-mesu',
        'en': 'tandem instructor',
    },
    'tandemInstructor.ok': {
        'fi': 'tandem-mesu ✓',
        'en': 'tandem instructor ✓',
    },
    'tandemInstructor.notFound': {
        'fi': 'tandem-mesun',
        'en': 'a tandem instructor',
    },
    'tandemVideo.label': {
        'fi': 'Tandem-kuvaajana',
        'en': 'Tandem camera flyer',
    },
    'tandemVideo.dayLabel': {
        'fi': 'tandem-kuvaaja',
        'en': 'tandem camera flyer',
    },
    'tandemVideo.ok': {
        'fi': 'tandem-kuvaaja ✓',
        'en': 'tandem camera flyer ✓',
    },
    'tandemVideo.notFound': {
        'fi': 'kuvaajan',
        'en': 'a camera flyer',
    },
    'radio.label': {
        'fi': 'Radiokouluttajana',
        'en': 'Radio instructor',
    },
    'radio.dayLabel': {
        'fi': 'radiokouluttaja',
        'en': 'radio instructor',
    },
    'radio.ok': {
        'fi': 'radiokouluttaja ✓',
        'en': 'radio instructor ✓',
    },
    'radio.notFound': {
        'fi': 'radiokouluttajan',
        'en': 'a radio instructor',
    },
    'pilot.label': {
        'fi': 'Pilotti',
        'en': 'Pilot',
    },
    'student.label': {
        'fi': 'Oppilas',
        'en': 'Student',
    },
    'student.addOrEditLabel': {
        'fi': 'Oppilas',
        'en': 'Student',
    },
    'skydiver.label': {
        'fi': 'Kelppari',
        'en': 'Licensed skydiver',
    },
    'skydiver.addOrEditLabel': {
        'fi': 'Kelppari',
        'en': 'Licensed skydiver',
    },
    'instructor.label': {
        'fi': 'Kouluttaja',
        'en': 'Instructor',
    },
    'support.label': {
        'fi': 'Toimihenkilö',
        'en': 'Support role',
    },
    'support.instructions': {
        'fi': `Rooli ei-hyppääville henkilöille. Kirjaa <i>Huom</i> kenttään missä roolissa toimit. Esimerkiksi "manifesti", "autokuski" tai "maahenkilö".`,
        'en': `For persons who are not going to jump. Write your role into the <i>Notes</i> field. For example, "ground person" or "manifest".`,
    },
    'save.label': {
        'fi': 'Tallenna',
        'en': 'Save',
    },
    'notes.label': {
        'fi': 'Huom',
        'en': 'Notes'
    },
    'day.noPilot': {
        'fi': 'Ei pilottia',
        'en': 'No pilot'
    },
    'day.noJumpers': {
        'fi': 'Ei hyppääjiä',
        'en': 'No jumpers'
    },
    'day.titleLabel': {
        'fi': 'Otsikko',
        'en': 'Title'
    },
    'day.descriptionLabel': {
        'fi': 'Kuvaus',
        'en': 'Description'
    },
    'day.copyPreviousDayLabel': {
        'fi': 'Kopioi edellinen päivä',
        'en': 'Copy previous day'
    },
    'help.title': {
        'fi': 'Ohjeet',
        'en': 'Instructions'
    },
    'help.textHtml': {
        'fi': `<p>
      Täydennä organisaattoriin nimesi, arvioitu aika jolloin olet hyppyvalmiina ja mahdolliset huomioitavat (esim. kyydin kaipailut / tarjoamiset).
    </p>
    <p>
      Hyppytoiminta&shy;organisaattoriin merkitään kellonaika, jolloin hyppääjä parhaan arvionsa mukaan on hyppyvalmiina.
      Tarkoittaen että on paikalla, hyppyvarusteet valmiina, haalari päällä ja valmiina nousemaan koneeseen.
      Jos aika tarkentuu, muuttuu tai hyppääjää päättääkin olla tulematta, hän muuttaa HTO:n merkinnän sen mukaiseksi.
    </p>`,
        'en': `<p>
      Please fill your name, estimated time when you'll be ready to jump, and any additional notes like if you need a ride to the airport.
    </p>`
    },
    'help.iconDescriptions': {
        'fi': 'Kuvakeiden selitykset',
        'en': 'Icon descriptions'
    },
    'help.languageLabel': {
        'fi': 'Kieli',
        'en': 'Language',
    },
    'help.footerHtml': {
        'fi': `<p>
      Löysitkö bugin? Onko mielessäsi jokin parannusehdotus?
      Laita viestiä <a href="https://forms.gle/YQfjaXkYZmCTZLQp9">yhteydenottolomakeella</a>!
    </p>`,
        'en': ''
    },
    'error.unexpected': {
        'fi': 'Hups, jokin meni pieleen :(',
        'en': 'Sorry, something went wrong :('
    },
    'student.stageSl': { 'fi': 'Alkeisoppilas', 'en': 'Elementary training' },
    'student.stageBasic': { 'fi': 'Perus/jatko-oppilas', 'en': 'Basic or advanced training' },
    'student.stageTandem': { 'fi': 'Tandem', 'en': 'Tandem' },
    'student.stageAff13': { 'fi': 'Taso 1 - 3', 'en': 'AFF 1 - 3' },
    'student.stageAff47': { 'fi': 'Taso 4 - 7', 'en': 'AFF 4 - 7' },
    'student.stageLabel': { 'fi': 'Koulutusvaihe', 'en': 'Jump program stage' },
    'student.needRadioInstructorLabel': { 'fi': 'Tarvitsen radiokouluttajan', 'en': 'I need a radio instructor' },
    'name.label': { 'fi': 'Koko nimi', 'en': 'Full name' },
    'readyTime.label': { 'fi': 'Valmiina kello', 'en': 'Ready (time)' },
    'roles.label': { 'fi': 'Toimin', 'en': 'Acting as' },
    'nav.previousDay': { 'fi': 'Edellinen päivä', 'en': 'Previous day' },
    'nav.nextDay': { 'fi': 'Seuraava päivä', 'en': 'Next day' },
    'nav.back': { 'fi': 'Takaisin', 'en': 'Go back' },
    'nav.editDate': { 'fi': 'Muokkaa päivän tietoja', 'en': 'Edit day details' },
    'nav.cancelAttendance': { 'fi': 'Peru ilmoittautuminen', 'en': 'Cancel attendance' },
    'toast.unexpectedError': { 'fi': 'Tapahtui virhe', 'en': 'Unexpected error' },
    'toast.personAddedSuccess': { 'fi': 'Ilmoittautuminen vastaanotettu', 'en': 'Attendance recorded' },
    'toast.personSavedSuccess': { 'fi': 'Tiedot tallennettu', 'en': 'Edit saved' },
    'toast.dayEditSuccess': { 'fi': 'Ilmoitus tallennettu', 'en': 'Edit saved' },
    'toast.attendanceCancelled': { 'fi': 'Ilmoittautuminen poistettu', 'en': 'Attendance cancelled' },
    'toast.undo': { 'fi': 'Peruuta', 'en': 'Undo' },
    'undefined': { 'fi': '', 'en': '' }
});
function useTranslationImpl(lang, overrides) {
    const langFn = typeof lang === "function" ? lang : () => lang;
    return (key) => {
        var _a, _b;
        try {
            return (_b = (_a = overrides === null || overrides === void 0 ? void 0 : overrides[key]) === null || _a === void 0 ? void 0 : _a[langFn()]) !== null && _b !== void 0 ? _b : translations[key][langFn()];
        }
        catch (cause) {
            console.error();
            throw new Error(`no translation for ${langFn()} ${key}`);
        }
    };
}
exports.useTranslationImpl = useTranslationImpl;
